<template>
    <div class="container mx-auto">
        <div class="mt-6 md:mt-16 px-6 md:px-16">

            <h1 class="font-bold text-40px uppercase tracking-wider">CK Post Leads</h1>

                <div class="flex flex-wrap bg-black p-8 -mx-2 mt-8">
                        <h2 class="font-bold uppercase text-2xl tracking-wider w-full"> Select &amp; Upload Export File </h2>
                        <form autocomplete="off" class="font-work-sans" v-on:submit.prevent="uploadData" method="post" ref="uploadForm">

                            <div class="w-full text-lg">
                                <input type="file" name="csv_file" id="csv_file" accept=".csv" required ref="csv_file" />
                            </div>

                            <div class="w-full mt-8 max-w-xs">
                                <button class="bg-white text-black font-dense tracking-wider text-4xl font-bold w-full border-b-3 border-turquoise leading-tight pt-2 outline-none relative" :class="{'cursor-not-allowed':processing}" type="submit">SUBMIT  <span class="spinner" v-if="processing"><span></span><span></span><span></span><span></span></span></button>
                            </div>
                        </form>
                        <div class="w-full mt-8" v-if="error">
                            <div class="text-red-600 text-xl font-work-sans">{{ errorMessage }}</div>
                        </div>
                        <div class="w-full mt-8" v-if="processing">
                            <div class="text-xl font-work-sans">Please wait and keep this page open while data is processing...</div>
                        </div>
                        <div class="w-full mt-8" v-if="done">
                            <div class="text-green-600 text-xl font-work-sans">Process completed successfully.</div>
                        </div>
                </div>

        </div>
    </div>
</template>


<script>
    export default {
        name: 'CkPostLeads',
        components: {

        },
        data: function() {
            return {
                processing: false,
                error: false,
                errorMessage: '',
                done: false
            }
        },
        methods: {
            uploadData: function() {
                this.error = false;
                this.processing = true;
                this.done = false;
                let app = this;
                const formData = new FormData();
                formData.append('csv_file', this.$refs.csv_file.files[0]);
                this.$http({
                    url: 'ck-post-leads',
                    method: 'POST',
                    data: formData
                }).then(function() {
                    app.done = true;
                    app.$refs.uploadForm.reset();
                }).catch(function(err) {
                    app.error = true;
                    app.errorMessage = err.response.data.message;
                }).finally(function(){
                    app.processing = false;
                });
            }
        }
    }
</script>

<style scoped>
    @keyframes spinner {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    .spinner {
        position: absolute;
        top: 25%;
        left: auto;
        right: 25%;
        transition-property: padding, opacity;
        transition-duration: 0.2s, 0.2s;
        transition-timing-function: ease-in, ease;
        transition-delay: 0s, 0.2s;
    }
    .spinner span {
        box-sizing: border-box;
        display: inline-block;
        position: absolute;
        right: 0;
        top: 0.15rem;
        width: 1.5rem;
        height: 1.5rem;
        opacity: 1;
        border: 3.4px solid #0DE9FF;
        border-radius: 50%;
        animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #0DE9FF transparent transparent transparent;
    }
    .spinner span:nth-child(1) {
        animation-delay: 0.45s;
    }
    .spinner span:nth-child(2) {
        animation-delay: 0.3s;
    }
    .spinner span:nth-child(3) {
        animation-delay: 0.15s;
    }
</style>